<template>
  <div class="paperWrap">
    <div class="noData" v-if="paperList.length == 0">
      <img src="../assets/images/noData.png" alt="" />
      <p>暂无更多</p>
    </div>
    <template v-else>
      <ul class="mainWrap">
        <li v-for="(item, index) in paperList" :key="index">
          <div class="itemWrap">
            <div class="leftWrap">
              <p>{{ item.title }}</p>
              <div class="bottomWrap">
                <span>共{{ item.question_num }}题</span>
                <!--              <span>{{ item.year }}</span>-->
                <!--              <span>{{ item.person }}人答过</span>-->
                <span v-if="item.mark != ''">{{ item.mark }}</span>
              </div>
            </div>
            <div class="btnWrap" @click="toHistory(item)">
              <div v-if="item.answer_staus == 0&&item.answer_id==0">开始作答</div>
              <div v-if="item.answer_staus == 0&&item.answer_id!=0">继续作答</div>
              <div v-if="item.answer_staus == 1">查看报告</div>
            </div>
          </div>
          <div class="hideWrap">
            <div @click="delcollect(item,index)">
              <i class="iconfont icon-qxsc"></i>
              取消收藏
            </div>
            <!-- <div><i class="iconfont icon-xz"></i>下载</div> -->
          </div>
        </li>
      </ul>
      <p class="moreBtn" @click="getMore">
        <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
      </p>
    </template>

  </div>
</template>

<script>
import { colparlist,delcollect } from "../assets/api";
export default {
  data() {
    return {
      paperList: [],
      page: 1,
      btnMessage: "加载更多",
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    toHistory(data) {
      if (data.answer_staus == 1) {
        this.$router.push({
          path: "/paper_report",
          query: {
            answer_id: data.answer_id,
            paper_id: data.paper_id,
            area_type: 5,
          },
        });
      } else {
        this.$router.push({
          path: "/pape_do",
          query: {
            answer_id: data.answer_id,
            paper_id: data.paper_id,
            area_type: 5,
          },
        });
      }
    },
    getList() {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        page: this.page
      };
      colparlist(params).then((res) => {
        if(res.code==0){
          if(res.data.length>0){
            if(this.paperList==0){
              this.paperList=res.data
            }else {
              this.paperList=this.paperList.concat(res.data)
            }
          }else {
            this.btnMessage='暂无更多';
          }
        }
      });
    },
    delcollect(da,index) {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        collecttype: 2,
        collect_id: da.collect_id,
      };
      delcollect(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "取消收藏成功",
            type: "success",
          });
          this.paperList.splice(index,1);
        } else {
          this.$message.error("取消收藏失败");
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getList();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.paperWrap {
  .noData {
    margin-top: 115px;
    margin-bottom: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    color: #999;
    img {
      width: 88px;
      height: 92px;
      margin-bottom: 25px;
    }
  }
  .mainWrap {
    overflow: hidden;
    li {
      padding: 0 30px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 24px;
      color: #333;
      position: relative;
      .itemWrap {
        padding: 24px 0;
        box-sizing: border-box;
        border-bottom: 1px dashed #e1e1e1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .leftWrap {
          .bottomWrap {
            font-size: 14px;
            color: #888;
            margin-top: 5px;
            span:not(:last-child) {
              margin-right: 20px;
              position: relative;
              &::after {
                clear: both;
                content: "";
                position: absolute;
                right: -10px;
                bottom: 0;
                width: 1px;
                height: 16px;
                background: #888;
              }
            }
          }
        }
        .btnWrap {
          margin-left: 50px;
          div {
            width: 80px;
            height: 32px;
            border-radius: 16px;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            border: 1px solid var(--default-color);
            color: var(--default-color);
            font-size: 14px;
            &:hover{
              color: #fff;
              background: var(--default-color);
            }
          }
        }
      }
      .hideWrap {
        display: none;
        position: absolute;
        right: 112px;
        top: 0;
        width: 280px;
        height: 100%;
        background: url("../assets/images/collectPaperBg.png") no-repeat;
        font-size: 14px;
        color: #666;
        div {
          margin-right: 25px;
          cursor: pointer;
          i.icon-qxsc {
            color: #ffb114;
          }
        }
      }
      &:hover {
        .hideWrap {
          display: block;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
  .moreBtn {
    margin: 30px auto;
    width: 100px;
    height: 30px;
    font-size: 14px;
    border-radius: 18px;
    border: 1px solid #e1e1e1;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #666;
    &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
    i {
      font-size: 14px;
    }
  }
}
</style>
